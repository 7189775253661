html {
  font-size: 100%;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

h1 {
  font-family: 'InterDisplay Regular', sans-serif;
  color: var(--text-primary);
  font-size: 3.8rem;
}

h2 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 1.5rem;
}

h3 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 1.2rem;
}

h4 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 1.1rem;
}

h5 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 0.9rem;
}

h6 {
  font-family: 'Roboto Mono', sans-serif;
  color: var(--primary-purple);
  font-size: 0.8rem;
  text-transform: uppercase;
}

.displayheading {
  // font-family: 'Roboto Mono', sans-serif;
  font-size: 200%;
}

body, p {
  font-family: 'Inter Regular', sans-serif;
  font-size: 0.85rem;
  margin-bottom: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
}

@media screen and (max-width: 840px) {
  h1 {
    font-size: 2.8rem;
  }
  h2 {
    font-size: 1.6rem;
  }
  // h6 {
  //   font-size: 0.7rem;
  // }
}

.italic {
  font-family: "Inter SemiBoldItalic";
}

.large {
  font-size: 105%;
}

.small {
  font-size: 85%;
}

.tiny {
  font-size: 70%;
}

.micro {
  font-size: 50%;
}

.bold {
  font-family: "Inter Bold";
}

.muted {
  color: var(--text-secondary);
}

.danger {
  color: var(--primary-red);
}

.ai {
  color: var(--primary-purple);
}

a {
  color: var(--link-primary);
}

a:hover {
  color: var(--link-primary-hover);
  text-decoration: none;
}

.link {
  color: var(--link-primary);
}

.link:hover {
  color: var(--link-primary-hover);
  text-decoration: none;
  cursor: pointer;
}

.embedded-html {
  font-family: 'Inter Regular', sans-serif;
  font-size: 0.85rem;
  margin-bottom: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
}

pre {
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.75rem;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
  padding: 15px;
  margin-top: 10px;
  
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

hr {
  background-color: var(--separator);
  border: none;
  height: 1px;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.hovering-row:hover {
  background-color: var(--bg-primary);
}

.hovering-hidden {
  visibility: hidden;
}

.hovering-trigger:hover > .hovering-hidden {
  visibility: visible;
}

// code {
//   background-color: #EEEEEE;
// }

/* 
  Navbar 
*/

.nav-link {
  font-family: 'Inter Medium', sans-serif;
  color: var(--text-primary);
  text-transform: capitalize;
}

.nav-link:active {
  color: var(--text-primary-emphasized);
}

.dropdown-menu {
  background-color: var(--bg-primary);
  border-color: var(--separator);
}

.dropdown-divider {
  border-color: #CCCCCC;
}

.dropdown-item {
  font-family: 'Inter Regular', sans-serif;
  font-size: 0.8rem;
}

.dropdown-item:hover {
  background-color: transparent;
  // color: var(--text-secondary);
}

/*
  Table
*/

table {
  border: 1px solid var(--separator);
  margin-top: 10px;
  margin-bottom: 10px;
}

td {
  border: 1px solid var(--separator);
  font-family: 'Inter Regular', sans-serif;
  font-size: 0.8rem;
  padding: 8px;
}

th {
  border: 1px solid var(--separator);
  font-family: 'Inter Medium', sans-serif;
  font-size: 0.8rem;
  padding: 8px;
}

/*
  Buttons
*/

.btn {
  font-family: 'Inter Medium', sans-serif;
  font-size: 0.85rem;
  padding: 8px 18px;
  border-radius: 10px;
  border-width: 0px;
  background-clip:  padding-box;
}

.btn-primary {
  // text-transform: uppercase !important;
  color: var(--btn-text-primary) !important;
  background-color: var(--btn-bg-primary) !important;
  border: 2px solid var(--btn-bg-primary) !important;
}

.btn-primary:hover {
  background-color: var(--btn-bg-primary-hover) !important;
  border: 2px solid var(--btn-bg-primary-hover) !important;
}

.btn-primary:disabled {
  background-color: var(--btn-bg-primary-disabled) !important;
  border: 2px solid var(--btn-bg-primary-disabled) !important;
}

.btn-secondary {
  font-size: 0.8rem;
  border-radius: 8px;
  padding: 4px 10px !important;
  color: var(--btn-text-secondary); //!important;
  background-color: var(--btn-bg-secondary); // !important;
  border: 2px solid var(--btn-bg-secondary);// !important;
}

.btn-secondary:hover {
  background-color: var(--btn-bg-secondary-hover);//!important;
  border: 2px solid var(--btn-bg-secondary-hover);//!important;
}

.btn-secondary:disabled {
  background-color: var(--btn-bg-secondary-disabled); //!important;
  border: 2px solid var(--btn-bg-secondary-disabled); //!important;
}

.btn-tertiary {
  font-size: 0.8rem;
  border-radius: 8px;
  padding: 4px 10px !important;
  color: var(--btn-text-tertiary) !important;
  background-color: var(--btn-bg-tertiary) !important;
  border: 2px solid var(--btn-bg-tertiary) !important;
}

.btn-tertiary:hover {
  background-color: var(--btn-bg-tertiary-hover) !important;
  border: 2px solid var(--btn-bg-tertiary-hover) !important;
}

.btn-tertiary:disabled {
  background-color: var(--btn-bg-tertiary-disabled) !important;
  border: 2px solid var(--btn-bg-tertiary-disabled) !important;
}

.btn-danger {
  padding: 4px 12px !important;
  color: #EEEEEE !important;
  background-color: var(--primary-red) !important;
  border: 2px solid var(--primary-red) !important;
}

.btn-danger:hover {
  background-color: var(--secondary-red) !important;
  border: 2px solid var(--secondary-red) !important;
}

.btn-danger-small {
  padding: 2px 8px !important;
  color: #EEEEEE !important;
  background-color: var(--primary-red) !important;
  border: 2px solid var(--primary-red) !important;
}

.btn-danger-small:hover {
  background-color: var(--secondary-red) !important;
  border: 2px solid var(--secondary-red) !important;
}

.btn-ai {
  padding: 4px 12px !important;
  color: #FFFFFF !important;
  background-color: var(--primary-purple) !important;
  border: 2px solid var(--primary-purple) !important;
}

.btn-ai:hover {
  background-color: var(--secondary-purple) !important;
}

.btn-link {
  padding: 0px !important;
  border: none !important;
  background-color: none !important;
  text-transform: none !important;
  color: var(--link-primary) !important;
  cursor: pointer !important;
  border-radius: 0;
}

.btn-link:hover {
  color: var(--link-primary-hover) !important;
  text-decoration: none !important;
}

.btn-ailink {
  padding: 0px !important;
  border: none !important;
  background-color: none !important;
  text-transform: none !important;
  color: var(--primary-purple) !important;
  cursor: pointer !important;
}

.btn-ailink:hover {
  color: #d488ff !important;
  text-decoration: none !important;
}

.btn-group .btn {
  padding: 5px 14px !important;
}

.btn-on {
  padding: 4px 12px !important;
  color: #EEEEEE !important;
  background-color: #138f70 !important;
  border: 2px solid #138f70 !important;
}

.btn-off {
  padding: 4px 12px !important;
  color: #666666 !important;
  background-color: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
}

.btn-off-reversed {
  padding: 4px 12px !important;
  color: #EEEEEE !important;
  background-color: #666666 !important;
  border: 2px solid #666666 !important;
}

/*
  AnyButton EditorJS Tool
*/

.anyButtonContainer__registerButton {
  font-family: 'Inter Bold', sans-serif !important;
  font-size: 0.8rem !important;
  text-transform: uppercase !important;
  padding: 4px 12px !important;
  color: #FFFFFF !important;
  background-color: #1a73e8 !important;
}

/*
  Pill
*/

.pill-tertiary {
  font-size: 0.8rem;
  border-radius: 16px;
  padding: 4px 12px !important;
  background-clip:  padding-box;
  color: var(--btn-text-tertiary) !important;
  background-color: var(--btn-bg-tertiary) !important;
  border: 2px solid var(--btn-bg-tertiary) !important;
  cursor: pointer;
}

.pill-tertiary:hover {
  background-color: var(--btn-bg-tertiary-hover) !important;
  border: 2px solid var(--btn-bg-tertiary-hover) !important;
}

.pill-tertiary:disabled {
  background-color: var(--btn-bg-tertiary-disabled) !important;
  border: 2px solid var(--btn-bg-tertiary-disabled) !important;
}



/*
  Card
*/

.card {
  background-color: var(--bg-primary);
  border: 1px solid var(--separator);
  // width: 300px;
  padding: 18px;
  border-radius: 5px;
}

.card-code {
  background-color: #EEEEEE;
}

/*
  Form
*/

.form-control {
  background-color: var(--form-text-field-bg);
  font-size: 0.8rem;
  height: 36px;
  color: var(--form-text-field-text);
  border: 1px solid var(--form-text-field-border);
  border-radius: 5px;
  -webkit-appearance: none;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

.form-control:focus {
  color: var(--form-text-field-text);
  background-color: var(--form-text-field-bg);
  outline: 1px solid var(--accent);
}

.form-control:disabled {
  background-color: var(--form-text-field-bg-disabled);
  color: var(--form-text-field-text);
}

.form-check-inline .form-check-input {
}

.form-label {
  margin: 0px;
  margin-bottom: 0px;
  color: var(--text-primary-emphasized);
  font-size: 0.8rem;
  font-family: 'Inter Medium';
}

.form-check-label {
  font-size: 0.8rem;
}

.form-text {
  font-size: 0.75rem;
  // margin-bottom: 5px;
}

thead {
  font-size: 0.8rem;
}

select {
  background-color: var(--form-text-field-bg);
  color: var(--form-text-field-text);
  border: 1px solid var(--form-text-field-border);
  border-radius: 1px;
  padding: 8px;
  font-size: 0.8rem;
  width: 100%;
  height: 36px;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

.form-control[readonly] {
  background-color: var(--form-text-field-bg-disabled);
}

.spinner-border {
  color: var(--separator);
}

textarea {
  background-color: var(--form-text-field-bg);
  color: var(--form-text-field-text);
  resize:none!important;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

input {
  background-color: var(--form-text-field-bg);
  color: var(--form-text-field-text);
  // background-color: #EEEEEE;
  // color: #000000;
  border: none;
  padding-top: 2px;
  padding-bottom: 2px;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  background: var(--text-primary);
}

.display-linebreak {
  white-space: pre-line;
}

.editable-input {
  padding: 6px;
  background-color: var(--form-text-field-bg);
  cursor: pointer;
  border: none;
}

.editable-input:focus {
  background-color: var(--form-text-field-bg);
  cursor: auto;
}

.editable-input:hover {
  background-color: var(--form-text-field-bg);
  cursor: auto;
}

/*
  Badge
*/

.badge {
  padding: 6px 10px;
  font-family: 'Inter Medium', sans-serif;
  text-transform: uppercase;
  // line-height: 0px;
  font-size: 0.75rem;
  color: var(--text-primary);
  border-radius: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-success {
  color: #EEEEEE;
  background-color: #248a58!important;
}

.bg-secondary {
  color: #eeeeeed2;
  background-color: #EEEEEE3a!important;
}

.bg-warning {
  color: #ffc107d2;
  background-color: #ffc1073a!important;
}

.bg-danger {
  color: #EEEEEE;
}

.bg-light {
  color: #000000;
  background-color: #CCCCCC!important;
}

.bg-dark {
  color: #EEEEEE;
  background-color: #000000!important;
}

/*
  Chips
*/

.chip {
  font-size: 0.9rem;
}

.bg-chip-author {
  color: var(--text-primary);
  background-color: var(--chip-author-bg);
  text-transform: none;
  border-radius: 5px;
}

.bg-chip-collection {
  color: var(--text-primary);
  background-color: var(--chip-collection-bg);
  text-transform: none;
}

.bg-chip-prompt {
  padding: 4px 8px;
  border-radius: 0px;
  font-family: 'Inter Regular', sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--text-primary);
  background-color: var(--chip-prompt-bg);
}

.bg-chip-mode {
  padding: 4px 8px;
  border-radius: 0px;
  font-family: 'Inter Regular', sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  color: var(--text-primary);
  background-color: var(--chip-mode-bg);
}

.bg-chip-model {
  padding: 4px 8px;
  border-radius: 0px;
  font-family: 'Inter Regular', sans-serif;
  text-transform: lowercase;
  font-size: 0.75rem;
  color: var(--text-primary);
  background-color: var(--chip-model-bg);
}

/*
  Navigation tabs
*/

.nav-tabs {
  color: var(--text-primary);
  background-color: none;
  // font-size: 0.8rem;
  padding: 0px;
}

.nav-tabs .nav-link {
  font-family: 'Inter Regular', sans-serif;
  background-color: #DDDDDD;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #DDDDDD;
  margin-right: 1px;
}

.nav-tabs .nav-link:hover {
  color: #000000;
  background-color: #EEEEEE;
  border: 1px solid #DDDDDD;
}

.nav-tabs .nav-link.active {
  font-family: 'Inter Medium', sans-serif;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #CCCCCC;
}

// .nav-tabs .nav-link.active:hover {
//   background-color: #999999;
// }

/*
  Navigation pills
*/

.nav-pills .nav-link.active {
  background-color: #222222; // #050A30;
  color: #EEEEEE;
  border-bottom: 2px solid #333333;
  border-radius: 0px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px 20px;
}

.nav-pills .nav-link {
  background-color: #000000; // #050A30;
  border: none;
  border-radius: 0px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px 20px;
}

// .nav-link:pauseOnHover {
//   background-color: blue;
// }

/*
  Navigation underline
*/

.nav-underline .nav-link.active {
  color: var(--text-primary);
  border-bottom: 2px solid var(--text-primary);
}

.nav-underline:hover {
  color: var(--text-primary);
}

/* Modal */

.modal {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.modal-content {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.modal-body {
  border: none;
  border-radius: 5px;
  // box-shadow: 0px 5px 15px rgba(0,0,0,.5);
  padding: 20px;
}

.modal-title {
  font-size: 0.9rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-header {
  padding: 10px;
}

// button:focus {
//   outline:0px !important;
//   -webkit-appearance:none;
//   box-shadow: none !important;
// }

// .centered-overlay {
//   display: block;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 50%;
//   left:0;
//   z-index:9999;
//   overflow:hidden;
// }

/*
  Accordion
*/

.accordion {
  background-color: none;
}

/* 
 Pro React Sidebar
*/

.ps-sidebar-root {
  border-width: 0px;
}

// .ps-submenu-content {
//   z-index: 1000;
// }

/* 
  Toast
*/

.toast {
  font-family: 'Inter Regular', sans-serif;
  // font-size: 1em;
  // background: #F9F9F9;
}

/*
  Independent scrolling content divs
*/

.independent-scrolling {
  height: 100vh;
  overflow: auto;
}

/*
  AG Grid
*/


.ag-theme-alpine-light {
  // --ag-font-size: 0.75rem;
  --ag-borders: none;
  --ag-row-border-color: var(--separator);
  --ag-background-color: var(--bg-primary);
  --ag-icon-font-family: 'agGridAlpine';
  --ag-selected-row-background-color: var(--bg-secondary);
}

.ag-theme-alpine-dark {
  // --ag-font-size: 0.75rem;
  --ag-borders: none;
  --ag-row-border-color: var(--separator);
  --ag-background-color: var(--bg-primary);
  --ag-icon-font-family: 'agGridAlpine';
  --ag-selected-row-background-color: var(--bg-secondary);
}

.ag-theme-alpine-light .ag-header {
  font-family: 'Inter Medium';
  background-color: transparent;
}

.ag-theme-alpine-dark .ag-header {
  font-family: 'Inter Medium';
  background-color: transparent;
}

.ag-theme-alpine-light .ag-cell-value {
  line-height: 22px !important;
  word-break: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ag-theme-alpine-dark .ag-cell-value {
  line-height: 22px !important;
  word-break: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ag-row-odd {
  background-color: transparent;
}

.ag-row:hover {
  background-color: var(--bg-secondary);
  cursor: pointer;
}

.ag-theme-alpine-light .ag-header {
  font-family: 'Inter Medium';
  // font-size: 0.8rem;
  // text-transform: capitalize;
  background-color: transparent;
}

.ag-theme-alpine-light .ag-cell-value {
  line-height: 22px !important;
  word-break: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

/*
  Color swatches
*/

.swatch-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
  // background-color: yellow;
}

.swatch-component {
  padding: 2px;
  background-color: #FFFFFF;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}

.swatch-popover {
  position: absolute;
  z-index: 2;
}

.swatch-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/*
  EditorJS
*/

/* Editor: Set xax width of block */
.ce-block__content, 
.ce-toolbar__content {
  max-width: 530px;
}

/* Image tool: Disable caption */
.image-tool__caption { display: none; }

/* Image tool: Disable settings */
.ce-popover-item[data-item-name="withBorder"],
.ce-popover-item[data-item-name="withBackground"],
.ce-popover-item[data-item-name="stretched"] {
  display: none;
}

/*
  CSV Importer
*/

.CSVImporter_FileSelector {
  border: 1px dashed var(--separator);
  padding: 4em;
  border-radius: .4em;
  background: #f0f0f0;
  text-align: center;
  color: #202020;
  cursor: pointer
}

.CSVImporter_FileStep__header {
  font-size: 1em;
}

.CSVImporter_Importer {
  background-color: #F9F9F9;
  border: 1px solid #DDDDDD;
}

.CSVImporter_FormatRawPreview__scroll {
  background-color: #EEEEEE;
  font-size: 0.8em;
}

.CSVImporter_TextButton {
  padding: 4px 12px !important;
  color: #FFFFFF !important;
  background-color: #1a73e8 !important;
  border: 2px solid #1a73e8 !important;
}

.CSVImporter_TextButton:hover {
  background-color: #1d5b9a !important;
  border: 2px solid #1d5b9a !important;
}

.CSVImporter_ColumnDragSourceArea__box {
  background-color: #F9F9F9;
}

/*
  Chatbot
*/

.chat-messages {
  display: flex;
  position: fixed;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-messages-library {
  display: flex;
  position: fixed;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-box {
  position: relative;
  bottom: 20px;
  border-radius: 5px;
  border: 1px solid var(--separator);
  // box-shadow: 3px 3px var(--bg-secondary);
}

.chat-box:focus-within {
  outline: 1px solid var(--accent)!important;
}

.chat-container {
  width: 100%;
  height: 100vh;
  // background-color: lightgrey;
}

.chat-fixed-element {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  width: calc(80% - 20px);
}

/*
  Search box
*/

.search-box {
  border-radius: 2px;
  border: 1px solid var(--separator);
  box-shadow: 5px 5px #b7b7b71a;
}

/*
  React PDF
*/

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
